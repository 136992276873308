/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import "./CreateNewQuizMainComponent.css";
import axios from "axios";
import { useEffect, useState, CSSProperties } from "react";
import { client } from "constants/APIFunctions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CheckCircle } from "@material-ui/icons";
import FullScreenLottie from "./FullScreenLottie";
import animationData from "./loadingnew.json"; // Replace with your animation file
import { Typography } from "@mui/material";
import Lottie from "react-lottie";
import { CONSTANTS } from "constants/Constants";
import { HashLoader } from "react-spinners";
import { sub_topic_list, subject_list, topic_list } from "./data/Precacheddata";

const styles = {
  Text: {
    fontSize: "16px",
    fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
    fontWeight: "bold",
    lineHeight: "24px",
    marginLeft: "10px",
  },

  ButtonSelected: {
    cursor: "pointer",
    padding: "0px 2px",
    border: "1px solid #030303",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: "#114c8a",
    color: "#ffffff",
    fontSize: "12px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 400,
    lineHeight: "14px",
    outline: "none",
    marginLeft: "8px",
    marginTop: "12px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
  },
  Button: {
    cursor: "pointer",
    padding: "0px 2px",
    border: "1px solid #030303",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: "#fff",
    color: "#030303",
    fontSize: "12px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 400,
    lineHeight: "14px",
    outline: "none",
    marginLeft: "8px",
    marginTop: "12px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
  },
};

const defaultProps = {
  text: "choose your subject",
};
const data = [
  { id: "1", title: "Item 1" },
  { id: "2", title: "Item 2" },
  { id: "3", title: "Item 3" },
  { id: "4", title: "Item 4" },
  { id: "5", title: "Item 5" },
  { id: "6", title: "Item 6" },
  // Add more items as needed
];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function CreateNewQuizMainComponent(props) {
  const [data, setData] = useState([]);
  const [animation, setAnimation] = useState(animationData);
  const [animationText, setAnimationText] = useState("AI is preparing your test");
  //const combinedClassName = `card-holder ${props.className}`;
  const getSubjects = () => {
    // var subjectData = localStorage.getItem(
    //   props.exams.exam_id ? props.exams.exam_id : "813b6e33-91ff-49f0-8e08-2f479c65e4e1"
    // );
    // if (subjectData) {
    //   setData(JSON.parse(subjectData));
    // }
    axios
      .get(`${CONSTANTS.APIURL}/entity/all`, {
        params: {
          exam_id: props.exams.exam_id
            ? props.exams.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          type: "subject",
          parent_id: props.exams.exam_id
            ? props.exams.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.entities);
        // localStorage.setItem(
        //   props.exams.exam_id ? props.exams.exam_id : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        //   JSON.parse(response.data.entities)
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getLanguages = () => {
    axios
      .get(`${CONSTANTS.APIURL}/api/language/languages`)
      .then((response) => {
        console.log(response);
        setData(response.data.exams);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTopics = () => {
    axios
      .get(`${CONSTANTS.APIURL}/entity/all`, {
        params: {
          exam_id: props.exams.exam_id
            ? props.exams.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          type: "topic",
          parent_id: props.subject.entity_id
            ? props.subject.entity_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.entities);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubTopics = () => {
    axios
      .get(`${CONSTANTS.APIURL}/entity/all`, {
        params: {
          exam_id: props.exams.exam_id
            ? props.exams.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          type: "subtopic",
          parent_id: props.topic.entity_id
            ? props.topic.entity_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.entities);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getExams = () => {
    axios
      .get(`${CONSTANTS.APIURL}/api/exam/get_exams`)
      .then((response) => {
        console.log(response);
        setData(response.data.exams);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (props.type === 6) {
      getLanguages();
    }
    // if (props.type === 0 && props.exams) {
    //   getSubjects();
    // }
    // if (props.type === 4) {
    //   getExams();
    // }
    if (props.type === 3) {
      setData([
        {
          id: "easy",
          name: "Foundation",
          value: "Foundation",
        },
        {
          id: "medium",
          name: "Intermediate",
          value: "Intermediate",
        },
        {
          id: "hard",
          name: "Advanced",
          value: "Advanced",
        },
      ]);
    }
    if (props.type === 5) {
      setData([
        {
          id: "5",
          name: "5",
        },
        {
          id: "10",
          name: "10",
        },
      ]);
    }
  }, []);
  useEffect(() => {
    console.log(props.type, props.subject);
    if (props.type === 1 && props.subject) {
      getTopics();
      console.log("1", props.subject);
    }
    if (props.type === 2) {
      setData([]);
    }
  }, [props.subject]);
  useEffect(() => {
    console.log(props.type, props.exams);
    if (props.type === 0 && props.exams) {
      getSubjects();
    }
  }, [props.exams]);
  useEffect(() => {
    if (props.type === 2 && props.topic) {
      getSubTopics();
    }
  }, [props.topic]);
  return (
    <div className="card-holder">
      <div style={{ height: 2 }} />
      {/* {props.type !== 3 ? <SearchInput placeholder={props.placeholder} /> : <div />} */}
      <div style={styles.Text}>{props.title}</div>
      <div className="list-container">
        {data.map((item) => {
          // Conditionally render item based on type
          if (props.type === 0) {
            return (
              <div
                className={
                  props.subject?.entity_id == item?.entity_id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.entity_id}
                onClick={() => {
                  props.selectedSubject(item);
                  props.selectedTopic();
                  props.selectedSubTopic();
                  props.setShowOptions([
                    {
                      title: "Choose Topics",
                      type: 1,
                    },
                  ]);
                  props.onPress();
                }}
              >
                <div>{item.entity_name}</div>
                {props.subject?.entity_id == item?.entity_id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
              </div>
            );
          } else if (props.type === 1) {
            return (
              <div
                className={
                  props.topic?.entity_id == item?.entity_id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.entity_id}
                onClick={() => {
                  props.selectedTopic(item);
                  props.selectedSubTopic();
                  props.setShowOptions([
                    {
                      title: "Choose Sub Topics",
                      type: 2,
                    },
                  ]);
                  props.onPress();
                }}
              >
                <div>{item.entity_name}</div>
                {props.topic?.entity_id == item?.entity_id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
              </div>
            );
          } else if (props.type === 2) {
            return (
              <div
                className={
                  props.subTopic?.entity_id == item?.entity_id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.entity_id}
                onClick={() => {
                  props.selectedSubTopic(item);
                }}
              >
                <div>{item.entity_name}</div>
                {props.subTopic?.entity_id == item?.entity_id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
              </div>
            );
          } else if (props.type === 3) {
            return (
              <div
                className={
                  props.difficulty?.id === item?.id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.id}
                onClick={() => {
                  props.selectedDifficulty(item);
                }}
              >
                {props.difficulty?.id == item?.id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
                <div style={{ marginLeft: "8px" }}>{item.name}</div>
              </div>
            );
          } else if (props.type === 5) {
            return (
              <div
                className={
                  props.num?.id === item?.id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.id}
                onClick={() => {
                  props.selectnum(item);
                }}
              >
                {props.num?.id == item?.id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
                <div style={{ marginLeft: "8px" }}>{item.name}</div>
              </div>
            );
          } else if (props.type === 4) {
            return (
              <div
                className={
                  props.exams?.exam_id === item?.exam_id
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.exam_id}
                onClick={() => {
                  props.selectExams(item);
                }}
              >
                {props.exams?.exam_id == item?.exam_id ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
                <div style={{ marginLeft: "8px" }}>{item.exam_name}</div>
              </div>
            );
          }
          {
            /* else if (props.type === 6) {
            return (
              <div
                className={
                  props.language?.language_code === item?.language_code
                    ? "horizontal-list-item-new-selected"
                    : "horizontal-list-item-new"
                }
                key={item?.language_code}
                onClick={() => {
                  props.selectLanguage(item);
                }}
              >
                {props.language?.language_code == item?.language_code ? (
                  <KeyboardArrowUpIcon style={{ marginRight: "5px" }} />
                ) : (
                  <KeyboardArrowRightIcon style={{ marginRight: "5px" }} />
                )}
                <div style={{ marginLeft: "8px" }}>{item.language_name}</div>
              </div>
            );
          } */
          }
          // Add more conditions as needed
          return null; // Don't render if condition doesn't match
        })}
      </div>
    </div>
  );
}

export default CreateNewQuizMainComponent;
