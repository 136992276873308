import React, { useState, useEffect } from "react";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { CircularProgress, Box, Typography, Button, Grid } from "@mui/material";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import { useNavigate } from "react-router-dom";

// Mock data for testing
const testSchedule = {
  "2024-12-15": { topics: ["Math", "Science"], attended: true },
  "2024-12-16": { topics: ["History"], attended: false },
};

const DailyTest = () => {
  const [value, setValue] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState(null);
  const [userdata, setuserdata] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // Simulate API call delay
    // setTimeout(() => {
    //   const formattedDate = value.format("YYYY-MM-DD");
    //   setTestData(testSchedule[formattedDate] || null);
    //   setLoading(false);
    // }, 1000);
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    var userdata = items;
    const formattedDate = value.format("YYYY-MM-DD");
    axios
      .get(`${CONSTANTS.APIURL}/scheduled-test/scheduled-tests-by-date`, {
        params: {
          test_date: formattedDate,
          test_type: "daily",
          exam_id: userdata?.examPreference?.exam_id
            ? userdata?.examPreference?.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          user_id: userdata?.user_id,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data?.detail) {
          setTestData();
        } else {
          console.log(response.data[0]);
          setTestData(data[0]);
        }
        setLoading(false);
      });
  }, [value]);

  // Custom styling for calendar dates
  const renderDay = (date, selectedDate, dayProps) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const isToday = dayjs().isSame(date, "day");
    const isScheduled = !!testSchedule[formattedDate];
    const isAttended = testSchedule[formattedDate]?.attended;

    let backgroundColor = "";
    if (isToday) {
      backgroundColor = "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)";
    } else if (isScheduled) {
      backgroundColor = isAttended ? "green" : "red";
    }

    return (
      <div
        {...dayProps}
        style={{
          ...dayProps.style,
          background: backgroundColor,
          color: "#fff",
          borderRadius: "50%",
          padding: "0.5rem",
        }}
      >
        {date.date()}
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" mb={2} textAlign="center">
          Daily Test
        </Typography>
        <DateCalendar
          value={value}
          onChange={(newValue) => {
            console.log(newValue);
            setValue(newValue);
          }}
          renderDay={renderDay}
        />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 3 }}>
            {testData ? (
              <>
                <Typography variant="h6">Topics for {value.format("YYYY-MM-DD")}:</Typography>
                <ol>
                  {testData?.entities_available
                    ?.filter((item) => item?.entity_type === "topic")
                    .map((topic, index) => (
                      <li key={index}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "500" }}
                        >{` ${topic?.entity_name}`}</Typography>
                      </li>
                    ))}
                </ol>

                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,

                    background:
                      "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)",
                    transition: "all 0.3s ease",
                    color: "#fff",
                  }}
                  onClick={() => {
                    if (testData?.already_taken) {
                    } else {
                      navigate("/scheduledtest/TakeTest", {
                        state: {
                          data: {
                            test_id: testData?.scheduled_test_id,
                            user_id: userdata?.user_id,
                            test_type: "daily",
                          },
                        },
                      });
                    }
                  }}
                >
                  {testData?.already_taken ? "View Results" : "Start Test"}
                </Button>
              </>
            ) : (
              <Typography variant="h6">No test scheduled on this date.</Typography>
            )}
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default DailyTest;
