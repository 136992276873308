// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import CreateQuizMainComponent from "./CreateQuizMainComponent";
import StartQuizButton from "./StartQuizButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CreateQuiz() {
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedSubTopic, setSelectedSubTopic] = useState();
  const [selectedDifficulty, setSelectedDifficulty] = useState();
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [selectedExams, setSelectedExams] = useState();
  const splitFunction = (string) => {
    return string?.split(",").length > 1 ? true : false;
  };
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CreateQuizMainComponent
                placeholder={"Search your Exams"}
                title={"Choose your Exams"}
                type={4}
                selectExams={setSelectedExams}
                exams={selectedExams}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CreateQuizMainComponent
                placeholder={"Select number of questions"}
                title={"Select number of questions"}
                type={5}
                num={selectedQuestions}
                selectnum={setSelectedQuestions}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CreateQuizMainComponent
                placeholder={"Search your Subjects"}
                title={"Choose your Subjects"}
                type={0}
                selectedSubject={setSelectedSubject}
                subject={selectedSubject}
                exams={selectedExams}
              />
            </Grid>
            {splitFunction(selectedSubject?.subject_id) ? (
              <div />
            ) : (
              <Grid item xs={12} md={6}>
                <CreateQuizMainComponent
                  placeholder={"Search your Topics"}
                  title={"Choose your Topics"}
                  type={1}
                  selectedTopic={setSelectedTopic}
                  topic={selectedTopic}
                  subject={selectedSubject}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {splitFunction(selectedSubject?.subject_id) ||
            splitFunction(selectedTopic?.topic_id) ? (
              <div />
            ) : (
              <Grid item xs={12} md={6}>
                <CreateQuizMainComponent
                  placeholder={"Search your Sub-topics"}
                  title={"Choose your SubTopics"}
                  type={2}
                  topic={selectedTopic}
                  selectedSubTopic={setSelectedSubTopic}
                  subTopic={selectedSubTopic}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <CreateQuizMainComponent
                placeholder={"Search your Subjects"}
                title={"Choose your Difficulty Level"}
                type={3}
                selectedDifficulty={setSelectedDifficulty}
                difficulty={selectedDifficulty}
              />
            </Grid>
          </Grid>
        </MDBox>
        <div
          style={{
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
            marginBottom: "30px",
            marginTop: "20px",
          }}
          onClick={() => {
            const data = {
              subject: selectedSubject,
              topic: selectedTopic,
              subTopic: selectedSubTopic,
              difficulty: selectedDifficulty,
              questions: selectedQuestions,
              exams: selectedExams,
            };
            navigate("/dashboard/TakeTest", { state: { data: data } });
          }}
        >
          <StartQuizButton />
        </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateQuiz;
