import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  Paper,
  Avatar,
  Divider,
} from "@mui/material";
import { Phone, Email, Help } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  card: {
    height: "100%",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  emailIconContainer: {
    backgroundColor: theme.palette.success.light,
  },
  emailIcon: {
    color: theme.palette.success.main,
  },
  contactLink: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
const HelpAndSettings = () => {
  const classes = useStyles();
  const phoneNumber = "+91 9353548879"; // Remove any special characters for the href
  const email = "office@scontinent.com";

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute mini />
        <Container className={classes.root}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              mt: 4,
              borderRadius: 2,
              background: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 4,
                gap: 2,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  width: 56,
                  height: 56,
                  boxShadow: 2,
                }}
              >
                <Help sx={{ fontSize: 32 }} />
              </Avatar>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 600,
                  background: "linear-gradient(45deg, #1976d2 30%, #9c27b0 90%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Help and Settings
              </Typography>
            </Box>

            <Divider sx={{ mb: 4 }} />

            <Grid container spacing={3}>
              {/* Phone Card */}
              <Grid item xs={12} md={6}>
                <a href={`tel:${phoneNumber}`} className={classes.contactLink}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Box display="flex" alignItems="center">
                        <Box className={classes.iconContainer}>
                          <Phone className={classes.icon} />
                        </Box>
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            Phone
                          </Typography>
                          <Typography variant="body2" color="textSecondary" fontWeight="500">
                            +91 9353548879
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </a>
              </Grid>

              {/* Email Card */}
              <Grid item xs={12} md={6}>
                <a href={`mailto:${email}`} className={classes.contactLink}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Box display="flex" alignItems="center">
                        <Box className={`${classes.iconContainer} ${classes.emailIconContainer}`}>
                          <Email className={classes.emailIcon} />
                        </Box>
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            Email
                          </Typography>
                          <Typography variant="body2" color="textSecondary" fontWeight="500">
                            {email}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default HelpAndSettings;
