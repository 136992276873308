/* eslint-disable react/prop-types */
import React from "react";
import ReactECharts from "echarts-for-react";

const DoughnutChart = ({ data }) => {
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal", // Legend orientation
      // bottom: "10%", // Add more spacing between the legend and the chart
      left: "center",
      padding: [10, 0, 0, 0], // Top padding to avoid overlap
    },
    series: [
      {
        name: "Access Source",
        type: "pie",
        radius: ["40%", "60%"], // Adjust radius to make room for the legend
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10, // Rounded corners
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "18",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };
  return (
    <>
      <div style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}>
        <ReactECharts option={option} style={{ height: "400px" }} />
      </div>
    </>
  );
};

export default DoughnutChart;
