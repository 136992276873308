import React from "react";
import { Line } from "react-chartjs-2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MultiAxisLineChart = () => {
  const [subject, setSubject] = React.useState("");

  const handleChange = (event) => {
    setSubject(event.target.value);
  };
  // Dummy data for the chart
  const data = {
    labels: ["T1", "T2", "T3", "T4", "T5"], // Test labels
    datasets: [
      {
        label: "Correct Answers",
        data: [45, 50, 60, 55, 65], // Correct answers data
        borderColor: "rgba(0, 255, 0, 1)",
        backgroundColor: "rgba(0, 255, 0, 0.2)",
        yAxisID: "y",
      },
      {
        label: "Wrong Answers",
        data: [10, 15, 8, 12, 5], // Wrong answers data
        borderColor: "rgba(255, 0, 0, 1)",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        yAxisID: "y",
      },
      {
        label: "Skipped Questions",
        data: [5, 3, 7, 8, 4], // Skipped questions data
        borderColor: "rgba(0, 0, 255, 1)",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        yAxisID: "y2",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 20, // Adjust the size of the legend box
          padding: 10, // Add some spacing between legend items
        },
      },
      title: {
        display: true,
        // text: "Multi-Axis Line Chart - Test Results",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Count",
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // Only show grid lines for y-axis
        },
        title: {
          display: true,
          text: "Skipped Questions",
        },
      },
    },
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Subject</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subject}
          label="Subject"
          onChange={handleChange}
          style={{ height: 50, marginBottom: 10 }}
        >
          <MenuItem value={"physics"}>Physics</MenuItem>
          <MenuItem value={"chemistry"}>Chemistry</MenuItem>
          <MenuItem value={"biology"}>Biology</MenuItem>
        </Select>
      </FormControl>
      <div>
        <Line data={data} options={options} style={{ height: 400 }} />
      </div>
    </>
  );
};

export default MultiAxisLineChart;
