// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Trending MockTests
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              9 new tests added
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          icon="notifications"
          title="UPSC challenge History"
          dateTime="27 Feb 8:40 PM"
        />
        <TimelineItem
          color="error"
          icon="inventory_2"
          title="UPSC challenge Politics"
          dateTime="27 Feb 8:40 PM"
        />
        <TimelineItem
          color="info"
          icon="shopping_cart"
          title="UPSC challenge Economics"
          dateTime="27 Feb 8:40 PM"
        />
        <TimelineItem
          color="warning"
          icon="payment"
          title="Current Affairs Mania"
          dateTime="27 Feb 8:40 PM"
        />
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
