/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import CreateQuizMainComponent from "./CreateQuizMainComponent";
import StartQuizButton from "./StartQuizButton";
import QuizTimer from "./TakeQuizTimer";
import QuizQuestionsRate from "./QuizQuestionsRate";
import QuizQuestionComponent from "./QuizQuestionComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { client } from "constants/APIFunctions";
import FullScreenLottie from "./FullScreenLottie";
import animationData from "./loadingnew.json"; // Replace with your animation file
import { auth } from "../../firebase/firebase";
import error from "./components/error.json";
import QuizTimeoutModal from "./components/QuizTimeoutModal";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");
import { Helmet } from "react-helmet";

const styles = {
  Text: {
    color: "#030303",
    fontSize: "30px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "45px",
    marginLeft: "8px",
  },
  Button: {
    cursor: "pointer",
    top: "32px",
    left: "977px",
    width: "300px",
    height: "50px",
    padding: "0px 8px",
    border: "1px solid #919191",
    boxSizing: "border-box",
    borderRadius: "20px",
    background: "gold",
    color: "#000000",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "bold",
    lineHeight: "24px",
    outline: "none",
  },
  ButtonSelected: {
    cursor: "pointer",
    top: "30px",
    left: "1188px",
    width: "300px",
    height: "50px",
    padding: "0px 8px",
    border: "0",
    boxSizing: "border-box",
    borderRadius: "20px",
    background: "gold",
    color: "#000000",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "bold",
    lineHeight: "24px",
    outline: "none",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: "rgb(172, 207, 103)",
    },
  },
};
const defaultProps = {
  text: "UPSC Quiz Page",
  label: "Skip",
  selectedLabel: "Submit",
};
function TakeQuiz(props) {
  const location = useLocation();
  const data = location.state && location.state.data;
  const quiztype = location.state && location.state.type;
  const [quizdata, setQuizdata] = useState([]);
  const [testId, setTestId] = useState();
  const [testName, setTestName] = useState();
  const [responses, setResponses] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [animation, setAnimation] = useState(animationData);
  const [animationText, setAnimationText] = useState("AI is preparing your test");
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [userdata, setuserdata] = useState();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    console.log(items);
    // console.log("exams", JSON.parse(items.interested_exams));
    var userdata = items;
    if (data) {
      client
        .post("/samai/v1/api/v1/test/start-test", data)
        .then((response) => {
          console.log(response);
          setQuizdata(response.data.questions);
          setResponses(response.data.questions);
          setTestId(response.data.test_id);
          setTestName(response.data.test_name);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
          // setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // alert("An error occurred");
          setAnimationText("An error occurred");
          setAnimation(error);
        });
    }
  }, []);
  const handleCloseModal = () => {
    setModalOpen(false);
    navigate("/samai-mocktest/TestResult", {
      state: {
        data: { ...data, test_id: testId, test_name: testName, responses: responses },
      },
      replace: true,
    });
    // Add any additional logic you need here, such as resetting the quiz state
  };
  function selectUserResponse(objectsArray) {
    // Filter the array to get objects with the user_response key
    const filteredObjects = objectsArray.filter((obj) => obj.hasOwnProperty("answer"));
    // Check if there are objects with the user_response key
    if (filteredObjects.length > 0) {
      // Return the first object with user_response key
      return true;
    } else {
      // Return null or handle the case where no object has the user_response key
      return false;
    }
  }
  const generateTestTitle = (data) => {
    const subjectName = data?.subject?.subject_name ? data.subject.subject_name : "";
    const topicName = data?.topic?.topic_name ? data.topic.topic_name : "";
    const subTopicName = data?.subTopic?.sub_topic_name ? data.subTopic.sub_topic_name : "";

    const title = `SAMAI${subjectName ? `-${subjectName}` : ""}${topicName ? `-${topicName}` : ""}${
      subTopicName ? `-${subTopicName}` : ""
    }-AI-Generated UPSC Mock Test`;

    return subjectName || topicName || subTopicName ? title : "SAMAI-AI-Generated UPSC Mock Test";
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{generateTestTitle(data)}</title>
        <meta
          name="description"
          content="Master exams with personalized AI-generated tests and previous year questions. Crack UPSC with Smart SAMAI. Get unlimited free AI-powered mock tests for UPSC, KPSC, and UPPSC. Practice with questions from 1500+ subjects. SAMAI: Best UPSC IAS Exam Prep AI for 2024-25."
        />
        <meta
          name="keyword"
          content="UPSC Preliminary Test Series,UPSC Prelims Key Answeres 2024,UPSC Prelims Key Answeres 2023,UPSC GS1 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS1 Prelims Key Answeres 2024, KPSC Preliminary Test Series Answer Key, KPSC Prelims Answer Key 2023,KPSC Prelims Answer Key 2024,UPSC Prelims Answer Key,KPSC Prelims Answer Key,UPSC Answer Key,UPSC Answe Key 2023,UPSC Answer 2024,csat answer key,upsc question paper with answer key,"
        />
        <meta
          property="og:description"
          content="Master exams with personalized AI-generated tests and previous year questions. Crack UPSC with Smart SAMAI. Get unlimited free AI-powered mock tests for UPSC, KPSC, and UPPSC. Practice with questions from 1500+ subjects. SAMAI: Best UPSC IAS Exam Prep AI for 2024-25."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://samai.scontiapp.com/upsc-kpsc-online-mocktest/TakeQuiz"
        />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link
          rel="canonical"
          href="https://samai.scontiapp.com/upsc-kpsc-online-mocktest/TakeQuiz"
        />
      </Helmet>

      <DashboardLayout>
        <DashboardNavbar absolute isMini title={data?.subject?.subject_name} />
        <QuizTimeoutModal isOpen={modalOpen} onClose={handleCloseModal} />
        <ToastContainer />
        {loading ? (
          <MDBox mt={8}>
            <FullScreenLottie content={animationText} animation={animation} />
          </MDBox>
        ) : (
          <MDBox mt={3}>
            <div
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "#f1eff0",
                padding: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <QuizTimer
                  initialTime={quizdata?.length > 0 ? quizdata.length * 60 : 600}
                  showModal={setModalOpen}
                />
              </div>
            </div>
            {quizdata.map((item, index) => {
              return (
                <QuizQuestionComponent
                  key={item.question}
                  data={item}
                  index={index}
                  setResponses={setResponses}
                  responses={responses}
                  type={quiztype}
                />
              );
            })}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              {/* <button style={styles.Button}>{props.label ?? defaultProps.label}</button> */}
              <button
                style={styles.ButtonSelected}
                onClick={() => {
                  if (selectUserResponse(responses)) {
                    navigate("/samai-mocktest/TestResult", {
                      state: {
                        data: {
                          ...data,
                          test_id: testId,
                          test_name: testName,
                          responses: responses,
                        },
                        quiztype: quiztype,
                      },
                      replace: true,
                    });
                  } else {
                    toast.error("You should answer at least one question");
                  }
                  ReactGA.event({
                    category: "Mock Test Consumer",
                    action: "mock test consumuer about to end the taking the mock test",
                  });
                }}
              >
                {props.selectedLabel ?? defaultProps.selectedLabel}
              </button>
            </div>
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default TakeQuiz;
