/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import CreateQuizMainComponent from "./CreateQuizMainComponent";
import StartQuizButton from "./StartQuizButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateQuizNew.css";
import { ArrowDropDownOutlined, ArrowDropDownSharp } from "@material-ui/icons";
import CreateNewQuizMainComponent from "./CreateNewQuizMainComponent";
import { ToastContainer, toast } from "react-toastify";
import { ArrowDropUpOutlined, ArrowDropUpSharp } from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";

function CreateQuizNew({ exam }) {
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedSubTopic, setSelectedSubTopic] = useState();
  const [selectedDifficulty, setSelectedDifficulty] = useState();
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [selectedExams, setSelectedExams] = useState({
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    exam_name: "UPSC",
  });
  const [isActive, setIsActive] = useState(true);
  const [userdata, setuserdata] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [arrayData, setArrayData] = useState([
    {
      name: "Subjects",
      type: 0,
      onPress: () => {
        setShowOptions([
          {
            title: "Choose Subjects",
            type: 0,
          },
        ]);
      },
    },
    {
      name: "Topics",
      type: 1,
      onPress: () => {
        setShowOptions([
          {
            title: "Choose Topics",
            type: 1,
          },
        ]);
      },
    },
    {
      name: "Sub topics",
      type: 2,
      onPress: () => {
        setShowOptions([
          {
            title: "Choose Sub Topics",
            type: 2,
          },
        ]);
      },
    },
    {
      name: "Language",
      onPress: () => {
        setShowOptions([
          {
            title: "Choose Language",
            type: 6,
          },
        ]);
      },
    },
  ]);
  const [showOptions, setShowOptions] = useState([
    {
      title: "Choose Subjects",
      type: 0,
    },
  ]);
  const splitFunction = (string) => {
    return string?.split(",").length > 1 ? true : false;
  };
  const navigate = useNavigate();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    var userdata = items;
    console.log("usersdatas", userdata);
    setuserdata(userdata);
    setSelectedExams(
      userdata?.examPreference
        ? userdata?.examPreference
        : {
            exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
            exam_name: "UPSC",
          }
    );
    setSelectedLanguage(userdata?.preferred_language ? userdata?.preferred_language : "English");
  }, [exam]);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ToastContainer />
      {/* <MDBox
        mt={1}
        style={{
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Box shadow
          borderRadius: 20, // Border radius
          padding: 10, // Optional padding
          backgroundColor: "#FFFFFF", // Background color
        }}
      >
        <div className="div-row-container">
          <div className="horizontal-list">
            {arrayData.map((item) => {
              return (
                <div
                  className="horizontal-list-item"
                  key={item}
                  onClick={() => {
                    item.onPress();
                    setIsActive(false); // Reset isActive to false before toggling
                    setTimeout(() => {
                      setIsActive(true); // Toggle isActive after a short delay
                    }, 50);
                  }}
                >
                  <div>{item.name}</div>
                  {showOptions[0].type == item.type ? (
                    <KeyboardArrowUpIcon style={{ width: "auto", height: "24px" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ width: "auto", height: "24px" }} />
                  )}
                </div>
              );
            })}
          </div>
          <button
            className="button-styles"
            onClick={() => {
              const data = {
                subject: selectedSubject,
                topic: selectedTopic,
                subTopic: selectedSubTopic,
                difficulty: selectedDifficulty,
                questions: selectedQuestions,
                exams: selectedExams,
                language: selectedLanguage,
              };
              navigate("/dashboard/TakeQuiz", { state: { data: data } });
            }}
          >
            {"Generate"}
          </button>
        </div>
      </MDBox> */}
      <div>
        {showOptions.map((item) => {
          return (
            <CreateNewQuizMainComponent
              id="mocktest-dashboard-subject-element-id"
              key={"Subject"}
              title={"Subject"}
              type={0}
              selectExams={setSelectedExams}
              exams={selectedExams}
              num={selectedQuestions}
              selectnum={setSelectedQuestions}
              selectedSubject={setSelectedSubject}
              subject={selectedSubject}
              selectedTopic={setSelectedTopic}
              topic={selectedTopic}
              selectedSubTopic={setSelectedSubTopic}
              subTopic={selectedSubTopic}
              selectedDifficulty={setSelectedDifficulty}
              difficulty={selectedDifficulty}
              language={selectedLanguage}
              selectLanguage={setSelectedLanguage}
              setShowOptions={setShowOptions}
              onPress={() => {
                setIsActive(false); // Reset isActive to false before toggling
                setTimeout(() => {
                  setIsActive(true); // Toggle isActive after a short delay
                }, 50);
              }}
            />
          );
        })}
      </div>
      <div>
        {showOptions.map((item) => {
          return (
            <CreateNewQuizMainComponent
              id="mocktest-dashboard-unit-element-id"
              key={"Unit"}
              title={"Unit"}
              type={1}
              selectExams={setSelectedExams}
              exams={selectedExams}
              num={selectedQuestions}
              selectnum={setSelectedQuestions}
              selectedSubject={setSelectedSubject}
              subject={selectedSubject}
              selectedTopic={setSelectedTopic}
              topic={selectedTopic}
              selectedSubTopic={setSelectedSubTopic}
              subTopic={selectedSubTopic}
              selectedDifficulty={setSelectedDifficulty}
              difficulty={selectedDifficulty}
              language={selectedLanguage}
              selectLanguage={setSelectedLanguage}
              setShowOptions={setShowOptions}
              onPress={() => {
                setIsActive(false); // Reset isActive to false before toggling
                setTimeout(() => {
                  setIsActive(true); // Toggle isActive after a short delay
                }, 50);
              }}
            />
          );
        })}
      </div>
      <div className={isActive ? "animated-div-active" : "animated-div"}>
        {showOptions.map((item) => {
          return (
            <CreateNewQuizMainComponent
              id="mocktest-dashboard-topic-element-id"
              key={"Topic"}
              title={"Topic"}
              type={2}
              selectExams={setSelectedExams}
              exams={selectedExams}
              num={selectedQuestions}
              selectnum={setSelectedQuestions}
              selectedSubject={setSelectedSubject}
              subject={selectedSubject}
              selectedTopic={setSelectedTopic}
              topic={selectedTopic}
              selectedSubTopic={setSelectedSubTopic}
              subTopic={selectedSubTopic}
              selectedDifficulty={setSelectedDifficulty}
              difficulty={selectedDifficulty}
              language={selectedLanguage}
              selectLanguage={setSelectedLanguage}
              setShowOptions={setShowOptions}
              onPress={() => {
                setIsActive(false); // Reset isActive to false before toggling
                setTimeout(() => {
                  setIsActive(true); // Toggle isActive after a short delay
                }, 50);
              }}
            />
          );
        })}
      </div>
      <div className="home-page-buttons-div">
        {/* <button
          className="button-styles button-styles-generate"
          // disabled="true"
          onClick={() => {
            const data = {
              subject: selectedSubject,
              topic: selectedTopic,
              subTopic: selectedSubTopic,
              difficulty: selectedDifficulty,
              questions: selectedQuestions,
              exams: selectedExams,
              language: selectedLanguage,
            };
            navigate("/upsc-kpsc-online-mocktest/TakeQuiz", { state: { data: data, type: "pyq" } });
          }}
        >
          {"Solve NCERT"}
        </button> */}
        <button
          className="button-styles button-styles-generate"
          onClick={() => {
            const data = {
              subject_name: selectedSubject?.entity_name ? selectedSubject?.entity_name : "General",
              topic_name: selectedTopic?.entity_name ? selectedTopic?.entity_name : "",
              sub_topic_name: selectedSubTopic?.entity_name ? selectedSubTopic?.entity_name : "",
              number_of_questions: Number.parseInt("10"),
              difficulty_level: "Advanced",
              user_id: userdata?.user_id,
              user_name: userdata?.full_name ? userdata?.full_name : "SamAI",
              subject_id: selectedSubject?.entity_id
                ? selectedSubject?.entity_id
                : userdata?.examPreference?.exam_id,
              topic_id: selectedTopic?.entity_id
                ? selectedTopic?.entity_id
                : userdata?.examPreference?.exam_id,
              sub_topic_id: selectedSubTopic?.entity_id
                ? selectedSubTopic?.entity_id
                : userdata?.examPreference?.exam_id,
              // exam_id: data?.exams?.exam_id ? data?.exams?.exam_id : "all",
              exam_id: userdata?.examPreference?.exam_id
                ? userdata?.examPreference?.exam_id
                : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
              exam_name: userdata?.examPreference?.exam_name
                ? userdata?.examPreference?.exam_name
                : "UPSC",
              user_type: "user",
              preferred_language: "English",
              preferred_language_code: "en",
              test_type: "all",
              // screenName,
            };
            navigate("/samai-mocktest/TakeTest", {
              state: { data: data, type: "normal" },
            });
          }}
        >
          {"Generate Test"}
        </button>
      </div>
    </div>
  );
}

export default CreateQuizNew;
