import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import { useNavigate } from "react-router-dom";

const WeeklyTest = () => {
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [alignment, setAlignment] = useState(0);
  const [loading, setLoading] = useState(false);
  const [weekData, setWeekData] = useState(null);
  const [weeklyListSchedule, setWeeklyListSchedule] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [currentMonth, setCurrentMonth] = useState();
  const [months, setMonths] = useState(); // Initialize with current 12 months
  const [userdata, setuserdata] = useState();
  const [testData, setTestData] = useState(null);
  const navigate = useNavigate();
  const weeklyData = {
    week1: { topics: ["Math", "Science"], attended: true },
    week2: { topics: [], attended: false },
    week3: { topics: ["English", "History"], attended: true },
    week4: { topics: [], attended: false },
    week5: { topics: [], attended: false },
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    setOpen(false);
    console.log(month?.name?.split(" ")[0], month?.name?.split(" ")[1]);
    getWeeklyTestsSchedule(month?.name?.split(" ")[0], month?.name?.split(" ")[1]);
  };

  function getCurrentWeekCount() {
    const currentDate = new Date();

    // Get the current month and year
    const currentMonths = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get the first day of the current month
    const firstDayOfMonth = new Date(currentYear, currentMonths, 1);

    // Calculate the difference in days between the current date and the first day of the month
    const dayDifference = Math.ceil((currentDate - firstDayOfMonth) / (1000 * 60 * 60 * 24));

    // Calculate the current week count (divide by 7 and add 1)
    const currentWeekCount = Math.ceil(dayDifference / 7);

    return currentWeekCount;
  }
  const getWeeklyTestsSchedule = (month, year) => {
    axios
      .get(`${CONSTANTS.APIURL}/scheduled-test/weeks`, {
        params: {
          month: month,
          year: year,
        },
      })
      .then((res) => {
        console.log("weekly test schedule", res.data);
        setWeeklyListSchedule(res.data);
      });
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
  }, []);

  useEffect(() => {
    if (weeklyListSchedule) {
      const weekItem = weeklyListSchedule[getCurrentWeekCount() - 1];
      getWeeklyTestsScheduleList(weekItem?.weekly_scheduled_date);
    }
  }, [weeklyListSchedule]);

  const getWeeklyTestsScheduleList = (formattedDate) => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.APIURL}/scheduled-test/scheduled-tests-by-date`, {
        params: {
          test_date: formattedDate,
          test_type: "weekly",
          exam_id: userdata?.examPreference?.exam_id
            ? userdata?.examPreference?.exam_id
            : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          user_id: userdata?.user_id,
        },
      })
      .then((response) => {
        var data = response.data;
        console.log("weekly lists schedule", data);
        if (data?.detail) {
          setTestData();
        } else {
          console.log(response.data[0]);
          setTestData(data[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("weekly error", err);
      });
  };
  const generateMonths = (currentDate) => {
    const newMonths = [];
    const currentYear = currentDate.getFullYear();
    for (let i = 0; i <= currentDate.getMonth(); i++) {
      const date = new Date(currentYear, i, 1); // Generate months from January
      const month = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      newMonths.push({ name: month });
    }
    return newMonths;
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedMonth = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    setMonths(generateMonths(currentDate)); // Generate months from January to the current month
    setSelectedMonth(formattedMonth); // Set the current month as selected
  }, []);
  // useEffect(() => {
  //   if (userdata && selectedMonth) {
  //     getWeeklyTestsScheduleList(week?.weekly_scheduled_date);
  //   }
  // }, [w]);
  useEffect(() => {
    const currentDate = new Date();

    // Format current month and year (e.g., "December 2023")
    const currentMonthYear = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    // Extract year and month separately
    const yearValue = currentDate.getFullYear().toString();
    const monthValue = currentDate.toLocaleString("default", { month: "long" });

    // Update state
    setCurrentMonth(currentMonthYear);
    getWeeklyTestsSchedule(monthValue, yearValue);
  }, []);
  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     setWeekData(weeklyData[alignment.toLowerCase()]);
  //     setLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(timer); // Cleanup timeout on component unmount or re-render
  // }, [alignment]);

  const DrawerList = (
    <Box sx={{ width: 250, padding: 2 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Select Month</Typography>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          <Cancel />
        </Button>
      </div>
      <List>
        {months?.map((month, index) => (
          <ListItem key={month} disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                border: selectedMonth?.name === month?.name ? "2px solid" : "none",
                // borderRadius: selectedMonth === month ? "20px" : "none",
                borderImage:
                  selectedMonth?.name === month?.name
                    ? "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%) 1"
                    : "none",
              }}
              onClick={() => handleMonthSelect(month)}
            >
              <Typography variant="body1" sx={{ width: 25 }}>{`${index + 1}`}</Typography>
              <Typography variant="body1">{month?.name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* <Box sx={{ p: 3 }}> */}
      <Typography variant="h4" mb={2} textAlign="center">
        Weekly Test
      </Typography>
      {/* </Box> */}
      <Box sx={{ padding: isSmallScreen ? 2 : 4 }}>
        <Button
          style={{
            marginTop: 25,
            // background: "linear-gradient(90deg, #ff8a00, #da1b60)",
            color: "#000",
          }}
          onClick={toggleDrawer(true)}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
        >
          {selectedMonth?.name || "Select Month"}
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <Box sx={{ marginTop: 4 }}>
          {/* <ToggleButtonGroup
            color="error"
            value={alignment}
            exclusive
            aria-label="Platform"
            sx={{ flexWrap: "wrap", gap: 2 }}
          > */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "16px", // Adds spacing between buttons
              justifyContent: "center", // Center alignment
              padding: "16px",
            }}
          >
            {weeklyListSchedule?.map((week, index) => (
              <ToggleButton
                key={index}
                value={week}
                onClick={() => {
                  setAlignment(index);
                  if (week?.weekly_scheduled_date) {
                    getWeeklyTestsScheduleList(week?.weekly_scheduled_date);
                  } else {
                    alert("No tests scheduled...");
                  }
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1, // Reduce gap
                  borderRadius: "12px", // Rounder corners
                  padding: "16px 24px", // Add padding for better spacing
                  minWidth: "150px", // Ensure consistent width
                  height: "120px", // Fixed height for uniformity
                  background:
                    alignment === index
                      ? "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)"
                      : "#fff",
                  color: alignment === index ? "#fff" : "#333",
                  boxShadow:
                    alignment === index
                      ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                      : "0 2px 4px rgba(0, 0, 0, 0.1)",
                  border: "none",
                  transition: "all 0.3s ease", // Smooth hover effect
                  cursor: "pointer",

                  "&:hover": {
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
                    transform: "translateY(-4px)", // Slight lift on hover
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    color: alignment === index ? "#fff" : "#555",
                  }}
                >
                  Week {index + 1}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: alignment === index ? "#fff" : "#777",
                    textAlign: "center",
                    marginTop: "8px",
                  }}
                >
                  {week?.weekly_scheduled_date || "N/A"}
                </Typography>
              </ToggleButton>
            ))}
          </div>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : testData?.entities_available ? (
            <>
              <Typography variant="h6">Topics for this week:</Typography>
              <ol>
                {testData?.entities_available
                  ?.filter((item) => item?.entity_type === "topic")
                  .map((topic, index) => (
                    <li key={index}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500" }}
                      >{` ${topic?.entity_name}`}</Typography>
                    </li>
                  ))}
              </ol>
              <Button
                variant="contained"
                sx={{
                  background: "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)",
                  transition: "all 0.3s ease",
                  color: "#fff",
                  marginTop: 2,
                }}
                onClick={() => {
                  if (testData?.already_taken) {
                  } else {
                    navigate("/scheduledtest/TakeTest", {
                      state: {
                        data: {
                          test_id: testData?.scheduled_test_id,
                          user_id: userdata?.user_id,
                          test_type: "weekly",
                        },
                      },
                    });
                  }
                }}
              >
                {testData?.already_taken ? "View Results" : "Start Test"}
              </Button>
            </>
          ) : (
            <Typography variant="h6">No test scheduled this week.</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default WeeklyTest;
