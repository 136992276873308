import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Box,
  Button,
  Avatar,
  Divider,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import {
  School,
  Class as ClassIcon,
  CalendarToday,
  MenuBook,
  Save,
  AccountBalance,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Create custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
          fontSize: "1rem",
          padding: "10px 24px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
          },
        },
      },
    },
  },
});

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const EducationalInformation = () => {
  const [institution, setInstitution] = useState("");
  const [currentClass, setCurrentClass] = useState("");
  const [targetYear, setTargetYear] = useState("");
  const [selectedExams, setSelectedExams] = useState([]);

  const institutions = [
    "Harvard University",
    "Stanford University",
    "MIT",
    "Oxford University",
    "Cambridge University",
  ];

  const classes = ["Freshman", "Sophomore", "Junior", "Senior", "Graduate"];

  const years = ["2024", "2025", "2026", "2027", "2028"];

  const exams = ["GRE", "GMAT", "TOEFL", "IELTS", "SAT", "ACT", "MCAT", "LSAT"];

  const handleExamChange = (event) => {
    const value = event.target.value;
    setSelectedExams(typeof value === "string" ? value.split(",") : value);
  };

  const handleSave = () => {
    console.log({
      institution,
      currentClass,
      targetYear,
      selectedExams,
    });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <ThemeProvider theme={theme}>
          <Container maxWidth="md" sx={{ mt: 6, mb: 6 }}>
            <Paper
              elevation={6}
              sx={{
                p: 4,
                borderRadius: 3,
                boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              }}
            >
              {/* Header Section */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 4,
                  gap: 2,
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "primary.main",
                    width: 56,
                    height: 56,
                    boxShadow: 2,
                  }}
                >
                  <AccountBalance sx={{ fontSize: 32 }} />
                </Avatar>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontWeight: 600,
                    background: "linear-gradient(45deg, #1976d2 30%, #9c27b0 90%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Educational Information
                </Typography>
              </Box>

              <Divider sx={{ mb: 4 }} />

              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {/* Institution Select */}
                <TextField
                  select
                  fullWidth
                  label="College/Institution Name"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                  placeholder="Select your institution"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <School color="primary" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {institutions.map((inst) => (
                    <MenuItem key={inst} value={inst}>
                      {inst}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Class Select */}
                <TextField
                  select
                  fullWidth
                  label="Current Class"
                  value={currentClass}
                  onChange={(e) => setCurrentClass(e.target.value)}
                  placeholder="Select your current class"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ClassIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {classes.map((cls) => (
                    <MenuItem key={cls} value={cls}>
                      {cls}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Target Year Select */}
                <TextField
                  select
                  fullWidth
                  label="Exam Target Year"
                  value={targetYear}
                  onChange={(e) => setTargetYear(e.target.value)}
                  placeholder="Select target year"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday color="primary" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Multi-select Exams */}
                <TextField
                  select
                  fullWidth
                  label="Choose Exam"
                  value={selectedExams}
                  onChange={handleExamChange}
                  placeholder="Select exams"
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(", "),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MenuBook color="primary" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {exams.map((exam) => (
                    <MenuItem key={exam} value={exam}>
                      <ListItemIcon>
                        <Checkbox checked={selectedExams.indexOf(exam) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={exam} />
                    </MenuItem>
                  ))}
                </TextField>

                {/* Save Button */}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Save />}
                  onClick={handleSave}
                  sx={{
                    mt: 2,
                    height: 56,
                    background: "linear-gradient(45deg, #1976d2 30%, #9c27b0 90%)",
                    // boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                    "&:hover": {
                      background: "linear-gradient(45deg, #1565c0 30%, #7b1fa2 90%)",
                    },
                  }}
                >
                  Save Profile
                </Button>
              </Box>
            </Paper>
          </Container>
        </ThemeProvider>
      </DashboardLayout>
    </>
  );
};

export default EducationalInformation;
