import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Avatar, Card, Typography, Container, Grid, Button, Box, CardContent } from "@mui/material";
import { Person, School, Description, KeyboardArrowRight, Edit } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import ChecklistIcon from "@mui/icons-material/Checklist";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, Link } from "react-router-dom";
import DoughnutChart from "./componets/DoughnutChart";

import MultiAxisLineChart from "./componets/MultiAxisLineChart";
import { CONSTANTS } from "constants/Constants";
import axios from "axios";

const UserProfile = () => {
  const menuItems = [
    { id: 1, name: "Profile Information", icon: <Person />, route: "/profile/edit-profile" },
    {
      id: 2,
      name: "Education Information",
      icon: <School />,
      route: "/profile/education-information",
    },
    { id: 3, name: "Help and Settings ", icon: <HelpIcon />, route: "/profile/help-and-settings" },
  ];
  const testData = [
    { name: "Daily Test", count: 45 },
    { name: "Weekly Test", count: 12 },
    { name: "Monthly Test", count: 6 },
    { name: "Revision Test", count: 15 },
    { name: "AI Test", count: 8 },
    { name: "PYQ Test", count: 20 },
    { name: "NCERT Test", count: 30 },
  ];
  const [alignment, setAlignment] = React.useState("11");
  const [userdata, setuserdata] = useState();
  const [studentsMetdata, setStudentsMetadata] = useState();
  const [guageChart, setGuageChart] = useState();
  const [filteredGuageChart, setFilteredGuageChart] = useState();
  function filterSubjectsByKeyword(subjects, keyword) {
    return subjects
      .filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()))
      .map((item) => ({
        value: item.value,
        name: item.name,
      }));
  }

  const handleAlignment = (event, newAlignment) => {
    console.log("classdata", newAlignment);
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      console.log("filtered data", filterSubjectsByKeyword(guageChart, newAlignment));
      setFilteredGuageChart(filterSubjectsByKeyword(guageChart, newAlignment));
    }
  };
  const navigate = useNavigate();

  const fetchUserProfile = (user_id) => {
    axios
      .get(`${CONSTANTS.AUTHURL}/auth/user/get-user-details`, {
        params: {
          user_id: user_id,
        },
      })
      .then((res) => {
        const items = JSON.parse(localStorage.getItem("userdata"));
        var userdata = { ...res.data, examPreference: items?.examPreference };
        localStorage.setItem("userdata", JSON.stringify(userdata));
        setuserdata(res.data);
      });
  };
  const fetchUserProfileMetadata = (user_id, exam_id) => {
    console.log("metadata", exam_id);
    axios
      .get(`${CONSTANTS.APIURL}/graph/student-test-metadata`, {
        params: {
          user_id: user_id,
          exam_id: exam_id,
        },
      })
      .then((res) => {
        const testData = Object.entries(res?.data).map(([key, value]) => {
          const nameMap = {
            daily_count: "Daily Tests",
            general_count: "General Tests",
            monthly_count: "Monthly Tests",
            weekly_count: "Weekly Tests",
            retake_count: "Retake Tests",
            pyq_count: "Previous Year Questions (PYQ)",
            ncert_count: "NCERT Based Tests",
          };
          return {
            name: nameMap[key] || key, // Map the key to a user-friendly name or default to the key
            count: value,
          };
        });
        setStudentsMetadata(testData);
      });
  };

  const fetchUserProfileAccuracy = (user_id, exam_id) => {
    axios
      .get(`${CONSTANTS.APIURL}/graph/subjectwise-score`, {
        params: {
          user_id: user_id,
          exam_id: exam_id,
        },
      })
      .then((res) => {
        const transformedData = res.data.map((item) => ({
          value: item.score,
          name: item.subject,
        }));
        setGuageChart(transformedData);
        setFilteredGuageChart(filterSubjectsByKeyword(transformedData, "11"));
      });
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    fetchUserProfile(items?.user_id);
    fetchUserProfileMetadata(items?.user_id, items?.examPreference?.exam_id);
    fetchUserProfileAccuracy(items?.user_id, items?.examPreference?.exam_id);
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Grid container spacing={3} direction="column" alignItems="center">
            {/* Profile Header */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Avatar sx={{ width: 120, height: 120, mb: 2 }}>
                <Person sx={{ fontSize: 120 }} />
              </Avatar>
              <Typography variant="h4" gutterBottom>
                {userdata?.full_name}
              </Typography>
            </Grid>

            {/* College and Exam Info */}
            <Grid item xs={12} sx={{ width: "100%" }}>
              <Grid container alignItems="center" justifyContent="center" spacing={1} gap={1}>
                <Grid item>
                  <School sx={{ color: "#000" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">{userdata?.college}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
                gap={1}
                sx={{ mt: 1 }}
              >
                <Grid item>
                  <ChecklistIcon sx={{ color: "#000" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {userdata?.interested_exams
                      ? userdata?.interested_exams?.map((exam) => exam.exam_name).join(",")
                      : "--"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
                gap={1}
                sx={{ mt: 1 }}
              >
                <Button
                  endIcon={<Edit />}
                  variant="outlined"
                  sx={{ color: "#000" }}
                  onClick={() => {
                    navigate("/profile/edit-profile");
                  }}
                >
                  Edit Profile
                </Button>
              </Grid>
            </Grid>

            {/* Menu Items */}
            <Grid item xs={12} sx={{ width: "100%" }}>
              {menuItems.map((item) => (
                <Card
                  key={item.id}
                  sx={{
                    mb: 2,
                    p: 2,
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigate(item.route);
                  }}
                >
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>{item.icon}</Grid>
                        <Grid item>
                          <Typography variant="subtitle1">{item.name}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon />
                    </Grid>
                  </Grid>
                </Card>
              ))}
              <Link to="https://scontiprivicy.web.app/">
                <Card
                  // key={item.id}
                  sx={{
                    marginBottom: 2,
                    padding: 2,
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <InfoIcon />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">Privacy Policy</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <KeyboardDoubleArrowRightIcon />
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
              p: 3,
            }}
          >
            <Card
              elevation={5}
              sx={{
                flex: 1,
                borderLeft: "4px solid #2196f3",
                minHeight: 200,
                boxShadow: 3,
                "&:hover": { boxShadow: 6 },
              }}
            >
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Completed Test
                </Typography>
              </CardContent>
              <Box sx={{ maxHeight: 300, overflow: "auto" }}>
                {studentsMetdata?.map((test, index) => (
                  <Box key={test.name}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 0.5,
                      }}
                    >
                      <Typography sx={{ flex: "0 0 40%" }}>{test.name}</Typography>
                      <Typography sx={{ flex: "0 0 20%" }}>{test.count}</Typography>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          color: "#fff",
                          background: "linear-gradient(to right, #6A42C2  51%, #DA22FF  100%)",
                        }}
                        endIcon={<KeyboardDoubleArrowRightIcon />}
                      >
                        View
                      </Button>
                    </Box>
                    {index < testData.length - 1 && <Divider />}
                  </Box>
                ))}
              </Box>
            </Card>

            <Card
              elevation={5}
              sx={{
                flex: 1,
                borderLeft: "4px solid #4caf50",
                minHeight: 200,
                boxShadow: 3,
                "&:hover": { boxShadow: 6 },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  My Accuracy
                </Typography>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                  size="small"
                >
                  <ToggleButton
                    value="11"
                    aria-label="left aligned"
                    sx={{
                      "&.Mui-selected": {
                        background: "linear-gradient(to right, #6A42C2  51%, #DA22FF  100%)", // Selected background color
                        color: "#fff", // Selected text color
                        "&:hover": {
                          backgroundColor: "#888", // Hover effect for selected
                        },
                      },
                    }}
                  >
                    <Typography fontWeight="600">11th</Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="12"
                    aria-label="centered"
                    sx={{
                      "&.Mui-selected": {
                        background: "linear-gradient(to right, #6A42C2  51%, #DA22FF  100%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#888",
                        },
                      },
                    }}
                  >
                    <Typography fontWeight="600">12th</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </CardContent>
              <CardContent>
                <DoughnutChart data={filteredGuageChart} />
              </CardContent>
            </Card>

            <Card
              elevation={5}
              sx={{
                flex: 1,
                borderLeft: "4px solid #9c27b0",
                minHeight: 200,
                boxShadow: 3,
                "&:hover": { boxShadow: 6 },
              }}
            >
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Score Rate Analysis
                </Typography>
              </CardContent>
              <CardContent>
                <MultiAxisLineChart />
              </CardContent>
            </Card>
          </Box>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default UserProfile;
