import React, { useState } from "react";
import {
  Avatar,
  TextField,
  Button,
  Box,
  Container,
  IconButton,
  Paper,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";
import {
  AccountCircle,
  Edit,
  Email,
  Phone,
  LocationOn,
  Home,
  CalendarToday,
  SaveAlt,
  Person,
} from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { AWSConstants } from "../awsconstant";
const s3Client = new S3Client({
  region: AWSConstants.region,
  credentials: {
    accessKeyId: AWSConstants.accessKey,
    secretAccessKey: AWSConstants.secretKey,
  },
});

function generateUniqueFilename(originalFilename) {
  const timestamp = Date.now(); // Current timestamp
  const fileExtension = originalFilename.split(".").pop(); // Extract file extension
  const baseFilename = originalFilename.replace(/\.[^/.]+$/, ""); // Remove extension

  // Generate unique filename
  return `${baseFilename}_${timestamp}.${fileExtension}`;
}

const ProfileInformation = () => {
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = async (file) => {
    try {
      const uniqueFilename = generateUniqueFilename(file.name);
      const params = {
        Bucket: AWSConstants.bucket,
        Key: uniqueFilename,
        Body: file,
        ContentType: file.type,
        ACL: "public-read", // Ensure the file is publicly accessible
      };
      const command = new PutObjectCommand(params);
      await s3Client.send(command); // Upload to S3
      return `https://${params.Bucket}.s3.${AWSConstants.region}.amazonaws.com/${uniqueFilename}`;
    } catch (err) {
      console.error("Error uploading file:", err);
      throw err;
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uploadedUrl = await handleImageUpload(file);

        // Log the uploaded URL to the console
        console.log("Image uploaded successfully:", uploadedUrl);

        // Set the uploaded image URL to state
        setImageUrl(uploadedUrl);
      } catch (err) {
        console.error("Failed to upload image:", err);
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />

        <Container maxWidth="sm">
          <Paper
            elevation={3}
            sx={{
              p: 4,
              mt: 4,
              borderRadius: 2,
              background: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 4,
                gap: 2,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#dedede",
                  width: 56,
                  height: 56,
                  boxShadow: 2,
                }}
              >
                <Person sx={{ fontSize: 32, color: "#000" }} />
              </Avatar>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 600,
                  background: "linear-gradient(45deg, #1976d2 30%, #9c27b0 90%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Profile Information
              </Typography>
            </Box>

            <Divider sx={{ mb: 4 }} />

            <Box display="flex" flexDirection="column" alignItems="center">
              <Box position="relative" mb={4}>
                <Avatar
                  src={imageUrl || ""}
                  sx={{
                    width: 120,
                    height: 120,
                    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                    top: 30,
                  }}
                >
                  {!imageUrl && <AccountCircle sx={{ width: 80, height: 80, color: "#000" }} />}
                </Avatar>

                <input
                  accept="image/*"
                  type="file"
                  id="avatar-upload"
                  hidden
                  onChange={handleImageChange}
                />
                <label htmlFor="avatar-upload">
                  <IconButton
                    component="span"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "#efefef",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                        transform: "scale(1.1)",
                      },
                      transition: "all 0.2s",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                    }}
                  >
                    <Edit sx={{ color: "#000", fontSize: 32 }} />
                  </IconButton>
                </label>
              </Box>

              <Box component="form" sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  label="Full Name"
                  placeholder="John Doe"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Email Address"
                  placeholder="john.doe@example.com"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Phone Number"
                  placeholder="+1 (555) 000-0000"
                  type="tel"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Address"
                  placeholder="123 Main Street, Apt 4B"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Location"
                  placeholder="New York, NY 10001"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />

                <TextField
                  fullWidth
                  label="Date of Birth"
                  type="date"
                  margin="normal"
                  variant="outlined"
                  defaultValue=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday sx={{ color: "#000", fontSize: 32 }} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mb: 3 }}
                />

                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<SaveAlt />}
                  sx={{
                    py: 1.5,
                    textTransform: "none",
                    fontSize: "1.1rem",
                    borderRadius: 2,
                    color: "#fff",
                    background: "linear-gradient(to right, #6A42C2  51%, #DA22FF  100%)",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 6px 16px rgba(0,0,0,0.2)",
                    },
                    transition: "all 0.2s",
                  }}
                >
                  Save Profile
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default ProfileInformation;
